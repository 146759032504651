import { useEffect, useState, useRef } from "react";

import { Helmet } from "react-helmet-async";

import "@fontsource/roboto/400.css";

// import SsmlEditor from "../components/SsmlEditor/SsmlEditor"
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import ShowMessage from "../components/ShowMessage/ShowMessage";
import Iconify from "../components/iconify/Iconify";
import { useParams, Link as RouterLink } from "react-router-dom";

// @mui
import {
  Avatar,
  Box,
  Container,
  Divider,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Typography,
  Card,
  Paper,
  CardMedia,
  CardContent,
  Stack,
  CircularProgress,
  FormControl,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import { alpha } from "@mui/material/styles";

import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.js";

import { useUserAuth } from "../UserAuthContext";

// components

export default function SocialPostPage() {
  const { user } = useUserAuth();
  const [caption, setCaption] = useState(
    "Commitment time! This time last year, I committed to the idea of being a full time"
  );
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(false);
  const [platform, setPlatform] = useState("LinkedIn");

  const { projectId } = useParams();

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleMediaUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMedia(URL.createObjectURL(file));
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Post Editor | Acoust AI </title>
      </Helmet>
      <Container>
        <Typography variant="h4">Post Creator</Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={4}
          p={2}
          alignItems="flex-start"
          mt={4}
        >
          {/* Form and Media Upload Section */}
          <Stack spacing={2} flex={1}>
            {/* Form Section */}
            <Stack spacing={2}>
              <FormControl>
                <InputLabel id="platform-label">Platform</InputLabel>
                <Select
                  labelId="platform-label"
                  value={platform}
                  label="Platform"
                  onChange={(e) => setPlatform(e.target.value)}
                >
                  <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                  <MenuItem value="Facebook">Facebook</MenuItem>
                  <MenuItem value="Twitter">Twitter</MenuItem>
                  {/* Add more platform options here */}
                </Select>
              </FormControl>

              <TextField
                label="Caption"
                multiline
                rows={4}
                fullWidth
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                helperText={`${caption.length} characters`}
              />
            </Stack>

            {/* Media Upload Section */}
            <Stack spacing={2}>
              {media ? (
                <Box sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    image={media.url}
                    alt="Uploaded Media"
                    sx={{ width: "100%" }}
                  />
                  <IconButton
                    //onClick={handleMediaDelete}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  component="label"
                  fullWidth
                  sx={{
                    border: "1px dashed",
                    borderColor: "grey.400",

                    textTransform: "none",
                    color: "grey.600",
                  }}
                >
                  + Add Media
                  <input
                    type="file"
                    hidden
                    onChange={handleMediaUpload}
                    accept="image/*,video/*,gif/*"
                  />
                </Button>
              )}
            </Stack>

            {/* Footer Section */}
            <Button variant="contained" color="primary" fullWidth>
              Plan or Publish
            </Button>
          </Stack>

          {/* Preview Section */}
          <Card
            sx={{
              flex: 1,
              minWidth: { xs: "100%", md: "300px" },
              maxWidth: { md: "400px" },
            }}
          >
            <CardContent>
              <Stack direction="row">
                <Avatar
                  alt="David S"
                  sx={{ width: 48, height: 48, marginRight: 2 }}
                />

                <Stack>
                  <Typography variant="body1" fontWeight="bold">
                    John Smith
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Subtitle Text
                  </Typography>
                </Stack>

                {media && (
                  <CardMedia
                    component="img"
                    image={media.url}
                    alt="Preview Media"
                    sx={{
                      mt: 2,
                      maxWidth: "100%",
                    }}
                  />
                )}
              </Stack>
              <Stack spacing={2} mt={2}>
                <Typography variant="body1">{caption}</Typography>

                <Divider></Divider>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
