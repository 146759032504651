import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import "@fontsource/roboto/400.css";

// import SsmlEditor from "../components/SsmlEditor/SsmlEditor"
import ReactPlayer from "react-player";
import ClipsMediaUploader from "../components/ClipsMediaUploader/ClipsMediaUploader";
import ShowMessage from "../components/ShowMessage/ShowMessage";
import Iconify from "../components/iconify";

// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

import {
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";

import { functions, httpsCallable } from "../firebase"; // import Firebase functions instance

import { db } from "../firebase.js";

import { useUserAuth } from "../UserAuthContext";

import { useNavigate } from "react-router-dom";

// components

export default function ClipsPage() {
  const { user } = useUserAuth();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clips, setClips] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [transcriptWords, setTranscriptWords] = useState([]);
  const [textDisplayMode, setTextDisplayMode] = useState("highlight");
  const [openDialog, setOpenDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showError, setShowError] = useState(false);
  const [clipsProjects, setClipsProjects] = useState([]);
  const navigate = useNavigate();
  const [uploadedFile, setUploadedFile] = useState({});
  const [usage, setUsage] = useState(0);
  const [quota, setQuota] = useState(0);
  const [sampelUrl, setSampleUrl] = useState("");

  const processVideo = (projectId) => {
    navigate(`/clipseditor/${projectId}`);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
    setFile(null); // Clear file if a URL is provided
  };

  const handleSubmit = async () => {
    let gsUrl = "";
    gsUrl = uploadedFile.gsUrl;
    console.log(gsUrl);
    if (url) {
      try {
        setProcessing(true);
        const fetchContentFunction = httpsCallable(functions, "ytdl");
        const result = await fetchContentFunction({ url, mode: "video" });
        // console.log(result.data);
        gsUrl = result.data.gsUrl;
        //console.log(contents);
        console.log("Using URL:", gsUrl);
      } catch {
        setShowError(true);
      } finally {
        setProcessing(false);
      }
    }
    const docRef = await addDoc(collection(db, "clips"), {
      user_email: user.email,
      user_id: user.uid,
      // file_name: "fname",
      timestamp: serverTimestamp(),
      video_url: gsUrl,
      status: "pending",
      text_mode: textDisplayMode,
      font_color: textDisplayMode === "one_word" ? "#FF8000" : "#FFFFFF",
      font_highlight_color: "#eb0000",
      // debug_mode: true,
    });
    processVideo(docRef.id);
  };

  const deletVideo = async (event, projectId) => {
    event.stopPropagation();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this video? This action cannot be undone."
    );

    if (isConfirmed) {
      try {
        const docRef = doc(db, "clips", projectId);
        await deleteDoc(docRef);
        setClipsProjects(clipsProjects.filter((row) => row.id !== projectId));
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const handleModeChange = (event) => {
    setTextDisplayMode(event.target.value);
  };

  const handleExampleClick = (url) => {
    setSampleUrl(url);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setVideoUrl("");
  };

  useEffect(() => {
    const loadProjects = async () => {
      setLoading(true);
      const q = query(
        collection(db, "clips"),
        where("user_email", "==", user.email),
        orderBy("timestamp", "desc")
      );

      const querySnapshot = await getDocs(q);
      const clipsProjectsDocs = [];
      querySnapshot.forEach((doc) => {
        clipsProjectsDocs.push({
          id: doc.id,
          timestamp: doc.data()["timestamp"].toDate().toLocaleString(),
          gsUrl: doc.data()["gs_url"],
          status: doc.data().status,
          clips: doc.data().clips,
        });
      });
      setClipsProjects(clipsProjectsDocs);
      return clipsProjectsDocs;
    };
    loadProjects().then(() => {
      const trackUsage = async () => {
        let snapDoc = doc(db, "customers", user.uid);

        //isTeamMember(user).then((team) => {
        //  if (team) {
        //    snapDoc = doc(db, "teams", team);
        //  }

        const unsubscribe = onSnapshot(snapDoc, (docSnapshot) => {
          if (docSnapshot.exists()) {
            // console.log("reloading customer...");
            const userData = docSnapshot.data();
            setUsage(userData.clips_usage);
            setQuota(userData.clips_quota);
            setLoading(false);
          }
        });

        // Clean up the subscription when the component unmounts
        return () => {
          unsubscribe();
        };
      };
      trackUsage().then(() => {
        setLoading(false);
      });
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> Clips | Acoust AI </title>
      </Helmet>
      <Container>
        <Typography variant="h4">
          AI Clips{" "}
          <sup
            style={{
              fontSize: "11px",
              color: "#0098fd",
              verticalAlign: "super",
            }}
          >
            beta
          </sup>
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: "#ccc", mb: 4 }}>
          Create shareable Shorts, Reels, and TikToks from your long videos
        </Typography>

        <Stack
          padding={2}
          spacing={2}
          sx={{
            bgcolor: (theme) => alpha(theme.palette.common.black, 0.7),
            borderRadius: "10px",
            border: "1px solid #616161",
          }}
        >
          <Stack>{processing && <LinearProgress />}</Stack>
          <ClipsMediaUploader
            setUploadedFile={setUploadedFile}
            setProcessing={setProcessing}
          />

          <Stack mt={2} spacing={2} mb={2}>
            <Typography variant="body2">
              👉 For best results, use videos with spoken words. Our AI analyzes
              the audio to identify the most engaging parts of the video.
            </Typography>
          </Stack>

          {Object.keys(uploadedFile).length > 0 && (
            <Stack direction="row" spacing={2}>
              <Typography variant="body2">{uploadedFile.fileName}</Typography>
              <Iconify
                sx={{ color: "success.main" }}
                icon="carbon:checkmark-filled"
              ></Iconify>
            </Stack>
          )}
          <Divider />
          {/* Other settings */}
          <Stack>
            <FormControl>
              <FormLabel id="display-mode-label">Subtitles Style</FormLabel>
              <RadioGroup
                row
                aria-labelledby="display-mode-label"
                name="display-mode-group"
                value={textDisplayMode}
                onChange={handleModeChange}
              >
                <FormControlLabel
                  value="one_word"
                  control={<Radio />}
                  label={
                    <Typography variant="body2">
                      One Word at a Time (
                      <Link
                        sx={{ textTransform: "none" }}
                        onClick={() =>
                          handleExampleClick(
                            "https://firebasestorage.googleapis.com/v0/b/textspeech-55a09.appspot.com/o/samples%2Fgifs%2Foneword.gif?alt=media"
                          )
                        }
                      >
                        {" "}
                        example{" "}
                      </Link>
                      )
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="highlight"
                  control={<Radio />}
                  label={
                    <Typography variant="body2">
                      Highlight Mode (
                      <Link
                        sx={{ textTransform: "none" }}
                        onClick={() =>
                          handleExampleClick(
                            "https://firebasestorage.googleapis.com/v0/b/textspeech-55a09.appspot.com/o/samples%2Fgifs%2Fhighlights.gif?alt=media"
                          )
                        }
                      >
                        {" "}
                        example{" "}
                      </Link>
                      )
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Stack>

          {/*<Stack minWidth="60%">
              <TextField
                rows={1}
                fullWidth
                label="Enter YouTube link"
                variant="outlined"
                value={url}
                onChange={handleUrlChange}
              />
            </Stack>*/}

          {/* Submit Button*/}
          {usage > 0 && usage >= quota ? (
            <Stack alignItems="center">
              <Typography sx={{ color: "red" }} variant="body2">
                You've exceeded your quota
              </Typography>
            </Stack>
          ) : (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={Object.keys(uploadedFile).length === 0}
            >
              Create Clips
            </LoadingButton>
          )}
        </Stack>

        {clipsProjects.length > 0 && (
          <Stack mt={2}>
            <Typography variant="h4">Recent Projects</Typography>
          </Stack>
        )}

        {loading ? (
          <Stack mt={2}>
            <CircularProgress />
          </Stack>
        ) : (
          <Stack mt={2}>
            <Grid container justifyContent="flex-start" spacing={4}>
              {clipsProjects.map((project) => (
                <Grid item key={project.id} sm="auto">
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => processVideo(project.id)}
                  >
                    <Stack spacing={2} alignItems="center">
                      <Typography variant="caption">
                        {project.timestamp}
                      </Typography>

                      {project.clips?.length > 0 ? (
                        <ReactPlayer
                          width="200px"
                          url={project.clips[0]}
                          preload="none"
                        />
                      ) : project.status === "pending" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 200 356"
                        >
                          <rect
                            fill="red"
                            stroke="red"
                            stroke-width="15"
                            stroke-linejoin="round"
                            width="30"
                            height="30"
                            x="85"
                            y="165"
                            rx="0"
                            ry="0"
                          >
                            <animate
                              attributeName="fill"
                              dur="3s"
                              values="red;orange;purple;red"
                              repeatCount="indefinite"
                            ></animate>
                            <animate
                              attributeName="stroke"
                              dur="3s"
                              values="red;orange;purple;red"
                              repeatCount="indefinite"
                            ></animate>
                            <animate
                              attributeName="rx"
                              calcMode="spline"
                              dur="2s"
                              values="15;15;5;15;15"
                              keySplines=".5 0 .5 1;.8 0 1 .2;0 .8 .2 1;.5 0 .5 1"
                              repeatCount="indefinite"
                            ></animate>
                            <animate
                              attributeName="ry"
                              calcMode="spline"
                              dur="2s"
                              values="15;15;10;15;15"
                              keySplines=".5 0 .5 1;.8 0 1 .2;0 .8 .2 1;.5 0 .5 1"
                              repeatCount="indefinite"
                            ></animate>
                            <animate
                              attributeName="height"
                              calcMode="spline"
                              dur="2s"
                              values="30;30;1;30;30"
                              keySplines=".5 0 .5 1;.8 0 1 .2;0 .8 .2 1;.5 0 .5 1"
                              repeatCount="indefinite"
                            ></animate>
                            <animate
                              attributeName="y"
                              calcMode="spline"
                              dur="2s"
                              values="80;270;80;"
                              keySplines=".6 0 1 .4;0 .8 .2 1"
                              repeatCount="indefinite"
                            ></animate>
                          </rect>
                        </svg>
                      ) : (
                        <Typography>Video Failed to process</Typography>
                      )}

                      <Stack
                        width="100%"
                        direction="row"
                        justifyContent="space-between"
                      >
                        {project.status === "completed" ? (
                          <Chip
                            color="success"
                            label="Ready"
                            sx={{
                              color: "#0cdc00",
                              backgroundColor: (theme) => alpha("#045400", 0.3),
                            }}
                          />
                        ) : project.status === "pending" ? (
                          <Chip
                            color="warning"
                            label="Processing"
                            sx={{
                              color: "#ffc354",
                              backgroundColor: (theme) => alpha("#ff8149", 0.3),
                            }}
                          />
                        ) : project.status === "failed" ? (
                          <Chip
                            color="error"
                            sx={{
                              color: "#fff",
                              backgroundColor: alpha("#cc0000", 0.9),
                            }}
                            label="Failed"
                          />
                        ) : null}

                        <IconButton
                          sx={{
                            color: "#cc0000",
                          }}
                          variant="outlined"
                          onClick={(event) => deletVideo(event, project.id)}
                        >
                          <Iconify icon={"material-symbols:delete-outline"} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
        {/* when clips are ready */}
        <Grid container spacing={2} mt={4}>
          {clips.map((url, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <video src={url} controls width="100%" />
            </Grid>
          ))}
        </Grid>
        {
          <Stack direction="row">
            <Stack width="50%">
              {/*transcript.map((subtitle, index) => (
                <div key={index}>
                  <span>
                    {subtitle.start} - {subtitle.end}{" "}
                  </span>
                  <span>{subtitle.word}</span>
                </div>
              ))}
            </Stack>
            <Stack>
              {transcriptWords.map((subtitle, index) => (
                <div key={index}>
                  <span>
                    {subtitle.start} - {subtitle.end}{" "}
                  </span>
                  <span>{subtitle.word}</span>
                </div>
              ))*/}
            </Stack>
            <Dialog open={openDialog} onClose={handleClose}>
              <DialogTitle>Sample</DialogTitle>
              <DialogContent>
                <Stack>
                  <img alt="text overlay style preview" src={sampelUrl} />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <ShowMessage
              showFileError={showError}
              setShowFileError={setShowError}
              severity="error"
              message="Oops! We couldn't process the video. Contact us if the issue persists"
            />
          </Stack>
        }
      </Container>
    </>
  );
}
