import { Navigate, useLocation, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";

// Pages
import AnimationPanel from "./Animations";
import EditAudio from "./audio/component/EditAudio";
import Audio from "./audio/page";
import EditVideo from "./media/component/EditVideo";
import Media from "./media/page";
import BillingPage from "./pages/BillingPage";
import BooksPage from "./pages/BooksPage";
import ClipsEditorPage from "./pages/ClipsEditorPage";
import ClipsPage from "./pages/ClipsPage";
import EditorPage from "./pages/EditorPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import LoginPageSSO from "./pages/LoginPageSSO";
import Page404 from "./pages/Page404";
import ReleaseNotesPage from "./pages/ReleaseNotesPage";
import SignupPage from "./pages/SignupPage";
import StreamPage from "./pages/StreamPage";
import SubscribePage from "./pages/SubscribePage";
import SummarizerPage from "./pages/SummarizerPage";
import SurveyPage from "./pages/SurveyPage";
import TeamAcceptPage from "./pages/TeamAcceptPage";
import TeamsPage from "./pages/TeamsPage";
import TemplatesPage from "./pages/TemplatesPage";
import ThanksPage from "./pages/ThanksPage";
import VerificationPendingPage from "./pages/VerificationPendingPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import VideoPage from "./pages/VideoPage.jsx";
import VoiceCloning from "./pages/VoiceCloning";
import VoiceSamplesPage from "./pages/VoiceSamplesPage";
import Settings from "./settings/page";
import Subtitles from "./subtitles/page";
import EditText from "./text/EditText";
import TextPanel from "./text/TextPanel";

import JarvisPage from "./pages/JarvisPage";

import { StoreContext } from "./canvas/store/canvasStore";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./UserAuthContext";
import SocialPostPage from "./pages/SocialPostPage.js";

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <ProtectedRoute replace state={{ from: location }}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/home" />, index: true },
        {
          path: "editor",
          element: (
            <ProtectedRoute>
              <EditorPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "billing",
          element: (
            <ProtectedRoute>
              <BillingPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "books",
          element: (
            <ProtectedRoute>
              <BooksPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "clips",
          element: (
            <ProtectedRoute>
              <ClipsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "post",
          element: (
            <ProtectedRoute>
              <SocialPostPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "clipseditor/:projectId",
          element: (
            <ProtectedRoute>
              <ClipsEditorPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "summary",
          element: (
            <ProtectedRoute>
              <SummarizerPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "home",
          element: (
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          ),
        },
        {
          path: "subscribe",
          element: (
            <ProtectedRoute>
              <SubscribePage />
            </ProtectedRoute>
          ),
        },
        {
          path: "samples",
          element: (
            <ProtectedRoute>
              <VoiceSamplesPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "cloning",
          element: (
            <ProtectedRoute>
              <VoiceCloning />
            </ProtectedRoute>
          ),
        },
        {
          path: "templates",
          element: (
            <ProtectedRoute>
              <TemplatesPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "team",
          element: (
            <ProtectedRoute>
              <TeamsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "teamaccept",
          element: (
            <ProtectedRoute>
              <TeamAcceptPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "jarvis",
          element: (
            <ProtectedRoute>
              <JarvisPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/thanks",
      element: (
        <ProtectedRoute replace state={{ from: location }}>
          <ThanksPage />
        </ProtectedRoute>
      ),
    },

    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "sso",
      element: <LoginPageSSO />,
    },
    {
      path: "signup",
      element: <SignupPage />,
    },
    {
      path: "verify",
      element: <VerifyEmailPage />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: "stream/:id", element: <StreamPage /> },
        { path: "new", element: <ReleaseNotesPage /> },
        { path: "404", element: <Page404 /> },
        { path: "vp", element: <VerificationPendingPage /> },
      ],
    },

    {
      path: "/v/:id",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <Media />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/media",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <Media />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/settings",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <Settings />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/edit-video/",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <EditVideo />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/edit-audio/",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <EditAudio />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/audio",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <Audio />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/subtitles",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <Subtitles />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/text",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <TextPanel />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/edit-text/",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <EditText />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
    {
      path: "/v/:id/animation/",
      element: (
        <ProtectedRoute>
          <VideoPage>
            <AnimationPanel />
          </VideoPage>
        </ProtectedRoute>
      ),
    },
  ]);

  return (
    <UserAuthContextProvider>
      <StoreContext>{routes}</StoreContext>
    </UserAuthContextProvider>
  );
}
